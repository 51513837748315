import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import { IParagraph } from "../../components/Article/types"

import {
  SecondarySection,
  Container,
  Article,
  Head,
} from "../../components/components"
import CallToAction from "../../widgets/CallToAction/CallToAction"

const article: IParagraph[] = [
  {
    subtitle: "Chancen",
    heading: "Was ist eine Kostenstruktur?",
    image: "reduceCost",
    paragraphs: [
      "Vom Kiosk an der Straßenecke, über die Boutique im Stadtzentrum bis hin zum Weltkonzern werden geplante Maßnahmen vor ihrer Umsetzung einer Kosten- und Leistungsrechnung unterzogen. Diese mag je nach Branche und Größe des Unternehmens in ihrem Umfang und der Art ihrer Ausführung variieren, jedoch verfolgt sie stets denselben Zweck: Kosten und Leistungen einander gegenüberzustellen und die Wirtschaftlichkeit von Prozessen, Abteilungen oder gar ganzen Betrieben auszuwerten. Im Rahmen dieser Analyse werden im ersten Schritt die Kostenarten zusammengetragen, welche sich von Unternehmen zu Unternehmen absolut unterschiedlich zusammensetzen können und somit eine individuelle Kostenstruktur ergeben. Je nach Zielsetzung können die Ergebnisse konträr ausfallen. So kommt es in der Fertigung typischerweise zu höheren Materialanschaffungs- und Fertigungskosten, welche in der Dienstleistungsbranche dagegen niedriger ausfallen. Mit der Digitalisierung wachsen jedoch auch die Möglichkeiten von Fixkosten abzurücken und immer mehr Bestandteile flexibel zu gestalten.",
    ],
  },
  {
    subtitle: null,
    heading: "Kosten senken durch Digitalisierung",
    image: null,
    paragraphs: [
      "Auch das Vorhaben, Prozesse zu digitalisieren, sollte sich rentieren. Dabei liegen die Vorteile von digitalisierten Prozessen auf der Hand. Die Automatisierung von Prozessen etwa bewirkt nicht nur eine Beschleunigung der Geschäftsabläufe, sie spart zudem auch Arbeitskräfte ein und verringert bzw. beseitigt das zuvor vorhandene Fehlerpotenzial. Durch die Beschleunigung der Abläufe kann darüber hinaus die Produktion hochgefahren und durch optimiertes Zeitmanagement effektiver gearbeitet werden. Die Digitalisierung bewirkt somit eine Optimierung auf den verschiedensten Ebenen.",
      "Neben den augenscheinlichen Vorzügen der Digitalisierung gibt es auch solche, die die Effizienz auf Umwegen steigern und somit nicht offensichtlich in Erscheinung treten. Sie sind dabei jedoch nicht weniger bedeutsam. Im Zeitalter des Online-Shoppings und sich stetig wechselnder Bedürfnisse hat sich der Markt den Gegebenheiten angepasst und Methoden hervorgebracht, die die nötige Flexibilität ermöglichen, um in einem solch schnelllebigen Markt wettbewerbsfähig zu bleiben. Alternative und vor allem innovative Geschäftsmodelle wie zum Beispiel das Dropshipping haben sich etabliert und ermöglichen es Händlern ihre Waren mit einem minimalen Risiko anzubieten, indem sie den Versand Ihrer Waren auslagern und Produkte in ihrem Namen direkt vom Hersteller verschiffen lassen. Auch Hersteller profitieren von neuen Geschäftsmodellen und vermeiden das Risiko hoher Mieten für potenziell leerstehende Lager durch Third Party Logistics Lösungen, indem sie ihre Produkte von externen Anbietern lagern und versenden lassen. Selbst Systeme, in denen der Service vom Versand über die Retourenbearbeitung bis hin zum Kundendienst ausgegliedert werden, sind nicht nur denkbar, sondern haben sich etabliert und verzeichnen große Erfolge.",
    ],
  },
  {
    subtitle: null,
    heading: "Digitalisierung heißt Verwirklichung",
    image: null,
    paragraphs: [
      "Konzentrieren Sie sich auf Ihre Kernkompetenzen. Ob Sie Ihre Dienstleistung im Rahmen des Outsourcings in den Dienst anderer Unternehmen stellen wollen oder selbst die Dienste anderer in Betracht ziehen, um Kapazitäten hochzufahren, die profitabler sind - die Möglichkeiten sind ebenso zahlreich wie lukrativ.",
      "Die Digitalisierung birgt völlig neue Möglichkeiten und stellt den Ansatz der Konzeptentwicklung auf den Kopf. Während man sich zuvor bereits bestehender Methoden und Werkzeuge bedienen musste, um innovative Konzepte zu erstellen, treten unsere Softwareentwickler heute als Schöpfer in Erscheinung und bilden die Grundlage für tatsächliche Innovationen. Diese werden erreicht, indem sie nach gemeinsamer Absprache eine Infrastruktur erschaffen, die sich nach Ihren individuellen Wünschen und Bedürfnissen richtet. Damit bieten wir Ihnen und Ihrem Geschäft die Möglichkeit, sich frei zu entfalten und das Potenzial Ihres Unternehmens voll auszuschöpfen. Wir nutzen dabei unsere Kompetenzen, um Ihre in Szene zu setzen.",
      "Dem Wachstum und den Möglichkeiten sind keine Grenzen gesetzt. Nicht zuletzt deshalb sollten Sie nicht zögern und anfangen zu profitieren, um Kosten effektiv umzuverteilen und Ihre Ressourcen in das Unternehmenswachstum zu investieren, anstatt sich mit Bestandsverwaltung zu begnügen. Kontaktieren Sie uns, um Ihre Möglichkeiten gemeinsam zu prüfen und Ihre Visionen Realität werden zu lassen.",
    ],
  },
]

const CostOptimization = ({ data }: { data: any }) => {
  return (
    <>
      <Head pagePath="/chancen/kostenstruktur-optimieren" />
      <SecondarySection withArticle>
        <Container style={{ alignItems: "center", justifyContent: "center" }}>
          <BlogContainer>
            <Article article={article} image={data.reduceCost.image} />
          </BlogContainer>
        </Container>
      </SecondarySection>
      <CallToAction type="customer" />
    </>
  )
}

export default CostOptimization

export const query = graphql`
  query {
    reduceCost: file(relativePath: { eq: "stock/reduceCost.jpg" }) {
      image: childImageSharp {
        gatsbyImageData(width: 800, height: 600)
      }
    }
  }
`
const BlogContainer = styled(Container)`
  max-width: 860px !important;
`
